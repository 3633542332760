import {debounce as _debounce, filter as _filter, find as _find, get as _get, isEmpty as _isEmpty} from 'lodash';
import {gql} from '@apollo/client';
import {withApollo} from '@apollo/client/react/hoc';
import {withRouter} from 'react-router';

import {getLink} from '@appComponents/Link';
import {refetchQueryByName} from '@utils/apollo';
import {convertToInt} from '@utils/helpers';
import {competitorsList as graphQLcompetitorsList} from '@graphql/competitors';
import {getStadiumsWithCities as graphQLgetStadiums} from '@graphql/stadiums';
import {SPORT_BADMINTON, SPORT_TENNIS} from '@constants/variables';

import EventForm from './EventForm';
import RightScope from './logic/RightScope';
import {coverageHelper, prefillCoverage} from '../utils/formHelper';

export class SportradarNonExistingEventForm extends EventForm {
    constructor(props) {
        super(props);

        this.filterStartDatetime = this.filterStartDatetime.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        coverageHelper(this.props.formValues.start_datetime,
            prevProps.formValues.start_datetime,
            this.state.productsFromUsages,
            this.state.selectedTournamentId,
            prevState.selectedTournamentId,
            this.props.location.pathname.includes('edit'),
            this.props.formValues.property_licence,
            prevProps.formValues.property_licence,
            prevState.productsFromUsages,
            this.props.GraphQLEntityData?.event?.products,
            this.showCoverage,
            this.getProductsForCoverage,
            this.setField);

        if (!this.state.selectedTournamentId && this.state.selectedTournamentId !== prevState.selectedTournamentId) {
            this.hideCoverage();
        }

        if (prevProps.formValues.event_preset !== this.props.formValues.event_preset) {
            prefillCoverage(
                this.props.Model.fields.coverage.props.hidden,
                this.state.presetProducts,
                this.props.Model,
                this.setField,
                this.state,
                (newState) => this.setState(newState)
            );
        }
    }

    getStadiumsByTournament(tournamentId) {
        return this.getStadiumsByTournamentData(tournamentId);
    }

    setCompetitorField = (competitor) => {
        const competitorObject = _get(this.state.defaultForm_data, `event.sr_event.${competitor}`, null);

        competitorObject && this.setField(`${competitor}_id`, {
            options: [{
                key: competitorObject.id,
                text: competitorObject.name,
                value: competitorObject.id,
            }],
        });
    };

    setCourtDropdown(tournamentObject) {
        if (!tournamentObject) {
            return;
        }

        let sportId = convertToInt(_get(this.state.defaultForm_data, 'event.property_licence.sport.id', 0));

        if (SPORT_TENNIS === sportId) {
            this.setField('court', {disabled: true, loading: true});

            this.getStadiumsByTournament(tournamentObject.id).then((stadiums) => {
                this.setState(() => ({stadiumsList: stadiums}));

                this.setField('court', {
                    defaultValue: (_get(this.state.defaultForm_data, 'event.sr_event.stadium.id', 0) || ''),
                    disabled: false,
                    loading: false,
                    options: stadiums,
                });
            });
        }
    }

    setStadiumDropdown() {
        const stadiumObject = _get(this.state.defaultForm_data, 'event.sr_event.stadium', null);

        stadiumObject && this.setField('stadium', {
            options: [{
                key: stadiumObject.id,
                value: stadiumObject.id,
                text: `${stadiumObject.name}${stadiumObject.city ? ` - ${stadiumObject.city.name}` : ''} (${stadiumObject.id})`,
            }],
        });
    }

    prepareDataForEditForm(propertyLicenceId) {
        this.setState(() => ({
            selectedPropertyLicenceId: propertyLicenceId,
            selectedTournamentId: _get(this.state.defaultForm_data, 'event.sr_event.tournament.id', null),
            propertyLicence: _get(this.state.defaultForm_data, 'event.property_licence', {}),
        }));

        this.setField('property_licence', {
            value: propertyLicenceId,
        });

        this.setField(['home_competitor_id', 'away_competitor_id'], {
            disabled: false,
        });

        const tournamentObject = _get(
            this.state.defaultForm_data, 'event.sr_event.tournament', null
        );

        if (tournamentObject) {
            let text = _get(tournamentObject, 'tournament_category.sport.name', '') + ' > '
                + _get(tournamentObject, 'tournament_category.name', '') + ' > '
                + tournamentObject.name;

            this.setField('tournament_id', {
                options: [{
                    id: tournamentObject.id,
                    key: tournamentObject.id,
                    text: text,
                    value: tournamentObject.id,
                }],
            });

            this.setState(() => ({
                tournamentsObjects: {
                    [tournamentObject.id]: tournamentObject,
                },
            }));
        }

        this.setCompetitorField('home_competitor');
        this.setCompetitorField('away_competitor');
        this.showCourtOrStadiumInput(propertyLicenceId);
        this.setCourtDropdown(tournamentObject);

        if (propertyLicenceId) {
            this.getPresetsForPropertyLicence(propertyLicenceId, _get(this.state, 'defaultForm_data.event.id', null));

            this.getRightsScopes(propertyLicenceId).then(() => {
                this.setField('start_datetime', {disabled: false, loading: false});
            });
        }

        this.setStadiumDropdown();
    }

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        if (!this.state.propertyLicences) {
            this.setState(() => ({
                propertyLicences: _get(nextProps, 'GraphQLOptionsData.property_licences', null),
            }));
        }

        if (this.props.Model.editForm
            && !this.state.selectedPropertyLicenceId
            && !_isEmpty(this.state.defaultForm_data)) {

            const propertyLicenceId = _get(this.state.defaultForm_data, 'event.property_licence.id', null);
            this.prepareDataForEditForm(propertyLicenceId);
        }
    }

    getTournamentOptionForDropdown(propertyLicence, tournament) {
        return {
            id: tournament.id,
            key: tournament.id,
            value: tournament.id,
            text: `${propertyLicence.sport.name} > 
                ${tournament.tournament_category.name} > ${tournament.name}`,
        };
    }

    getTournaments(propertyLicenceId, rightScopes) {
        if (!propertyLicenceId) {
            return [];
        }

        let tournamentListByPropertyLicenceId = _get(this.state, 'tournamentListByPropertyLicenceId', {}),
            tournamentsObjects = {};

        const propertyLicences = this.state.propertyLicences;

        if (_isEmpty(tournamentListByPropertyLicenceId)) {
            for (let i = 0; i < propertyLicences.length; i++) {
                let tournamentList = [];

                for (let contentIndex = 0; contentIndex < propertyLicences[i].contents.length; contentIndex++) {
                    const content = propertyLicences[i].contents[contentIndex];

                    let tournament = this.getTournamentOptionForDropdown(propertyLicences[i], content.tournament);

                    tournamentsObjects[content.tournament.id] = content.tournament;

                    if (!tournamentsObjects[tournament.id]) {
                        tournamentsObjects[tournament.id] = {
                            id: tournament.id,
                            name: tournament.text,
                            parent: null,
                            tournament_category: content.tournament.tournament_category
                        }
                    }

                    if (undefined === _find(tournamentList, {id: tournament.id})) {
                        tournamentList.push(tournament)
                    }
                }

                tournamentListByPropertyLicenceId[propertyLicences[i].id] = tournamentList;
            }

            this.setState(() => ({
                tournamentListByPropertyLicenceId: tournamentListByPropertyLicenceId,
                tournamentsObjects: tournamentsObjects
            }));
        }

        let tournamentsWithRightScopes = [];

        if (rightScopes.all) {
            tournamentsWithRightScopes = tournamentListByPropertyLicenceId[propertyLicenceId];
        }

        let propertyLicenceObject = _find(propertyLicences, {id: propertyLicenceId});

        for (let i = 0; i < tournamentListByPropertyLicenceId[propertyLicenceId].length; i++) {
            let tournament = tournamentListByPropertyLicenceId[propertyLicenceId][i],
                childrenTournaments = _filter(this.state.tournamentsObjects, {parent: {id: tournament.value}});

            if (rightScopes[tournament.value] && !_find(tournamentsWithRightScopes, {key: tournament.value})) {
                tournamentsWithRightScopes.push(tournament);
            }

            if (parseInt(_get(propertyLicenceObject, 'sport.id', 0), 10) === SPORT_TENNIS) {
                for (let index = 0; index < childrenTournaments.length; index++) {
                    let childTournament = childrenTournaments[index];

                    if (rightScopes[childTournament.id] && !_find(tournamentsWithRightScopes, {key: tournament.id})) {
                        tournamentsWithRightScopes.push(
                            this.getTournamentOptionForDropdown(propertyLicenceObject, childTournament)
                        );
                    }
                }
            }
        }

        return tournamentsWithRightScopes;
    }

    getCompetitors = (event, data) => {
        if (data.searchQuery.length < 3) {
            return;
        }

        this.setField(data.name, {
            loading: true,
        });

        let tournamentCategoryId = null,
            sportId = null;

        if (!this.props.Model.editForm) {
            tournamentCategoryId = _get(this.state.tournamentsObjects[this.state.selectedTournamentId],
                'tournament_category.id', null);
            sportId = _get(this.state.propertyLicence, 'sport.id', null);
        } else {
            tournamentCategoryId = _get(this.state.defaultForm_data, 'event.sr_event.tournament.tournament_category.id', null);
            sportId = _get(this.state.defaultForm_data, 'event.sr_event.tournament.tournament_category.sport.id', null);
        }

        this.runApolloRequest('query', {
            fetchPolicy: 'network-and-cache',
            query: gql(graphQLcompetitorsList),
            variables: {
                sport: parseInt(sportId, 10),
                tournamentCategory: parseInt(tournamentCategoryId, 10),
                search: data.searchQuery || '',
            }
        }).then((response) => {
            let optionsForDropdown = [];

            for (let i = 0; i < response.data.competitorsForDropdown.length; i++) {
                let competitor = response.data.competitorsForDropdown[i];

                optionsForDropdown.push({
                    id: competitor.id,
                    text: competitor.name + ` ( ${competitor.id} )`,
                    value: competitor.id,
                });
            }

            this.setField(data.name, {
                options: optionsForDropdown,
                loading: false,
                disabled: false,
            });
        }).catch(() => {
            this.setFormMessage('Can\'t get competitors.');
        });
    };

    showCourtOrStadiumInput = (propertyLicenceId) => {
        let stadiumFieldProps = {hidden: false},
            courtFieldProps = {hidden: true};

        let propertyLicence = _find(this.state.propertyLicences, {id: propertyLicenceId});

        if (this.props.Model.editForm) {
            propertyLicence = _get(this.state.defaultForm_data, 'event.property_licence', null);
        }

        this.setState(() => ({
            propertyLicence: propertyLicence,
        }));

        let sportId = parseInt(_get(propertyLicence, 'sport.id', 0), 10);

        if (this.props.Model.editForm) {
            sportId = parseInt(_get(this.state.defaultForm_data, 'event.property_licence.sport.id', 0));
        }

        if ([SPORT_TENNIS].includes(sportId)) {
            stadiumFieldProps = {hidden: true};
            courtFieldProps = {hidden: false};
        }

        stadiumFieldProps.label = (SPORT_BADMINTON === sportId) ? 'Court' : 'Venue';

        this.setField('stadium', stadiumFieldProps);
        this.setField('court', courtFieldProps);
    };

    getStadiums = (event, data) => {
        if (data.searchQuery.length < 3) {
            return;
        }

        this.setField('stadium', {
            loading: true,
            disabled: true,
        });


        this.runApolloRequest('query', {
            query: gql(graphQLgetStadiums),
            variables: {
                search: data.searchQuery || '',
            },
        }).then((response) => {
            let stadiumOptions = [];

            for (let i = 0; i < response.data.stadiums.length; i++) {
                let stadium = response.data.stadiums[i],
                    city = (stadium.city) ? ` - ${stadium.city.name}` : "";

                stadiumOptions.push({
                    id: stadium.id,
                    text: stadium.text + city + ` (${stadium.id})`,
                    value: stadium.id
                })
            }

            this.setField('stadium', {
                options: stadiumOptions,
                loading: false,
                disabled: false,
            });
        }).catch(() => {
            this.setFormMessage('Can\'t get stadiums.');
        });
    };

    filterStartDatetime(data, tournamentId = null) {
        if (null === tournamentId) {
            tournamentId = this.state.selectedTournamentId;
        }

        return RightScope.isValidDate(data, this.state.rightsScopes, tournamentId);
    }

    prepareDataForSubmit(data) {
        const homeCompetitorId = data.home_competitor_id ? parseInt(data.home_competitor_id, 10) : null,
            awayCompetitorId = data.away_competitor_id ? parseInt(data.away_competitor_id, 10) : null;
        let savedPresetId = _get(this.state, 'defaultForm_data.event.event_preset.id', null);
        let stadium = (SPORT_TENNIS === convertToInt(this.state.propertyLicence.sport.id))
            ? _get(data, 'court', null)
            : _get(data, 'stadium', null);
        const coverageToSave = data.coverage.filter(product => {
            return this.state.productsToCoverageFilteredByContentAndDate.find(prod => convertToInt(prod.value) === product);
        });

        let dataToSave = {
            event: {
                event_status: parseInt(data.event_status, 10),
                notes: data.notes || null,
                color: data.color && data.color.substring(1),
                description: data.description || null,
                event_type: 1,
                end_datetime: data.end_datetime,
                start_datetime: data.start_datetime,
                property_licence: parseInt(data.property_licence, 10),
                products: coverageToSave || null,
            },
            tournament: parseInt(data.tournament_id, 10),
            home_competitor: homeCompetitorId,
            away_competitor: awayCompetitorId,
            match: null,
            synchronise_times: false,
            stadium: stadium ? convertToInt(stadium) : null,
        };

        if (data.event_preset) {
            dataToSave.event.event_preset = !data.event_preset
                ? parseInt(savedPresetId) : parseInt(data.event_preset, 10);
        }

        if (data.id) {
            dataToSave.id = parseInt(data.id, 10);
        }

        if (undefined !== data.as_additional_contents) {
            dataToSave.as_additional_contents = !!data.as_additional_contents;
        }

        return this.addPresetDataForSubmit(dataToSave, data);
    }

    componentWillMount() {
        super.componentWillMount();

        this.setCreateErrorCallback(() => {
            this.props.Modal.setModal({
                isVisible: false,
            });
        });

        this.setCreateSuccessCallback((response) => {
            this.props.history.push(getLink('events.configuration.index', {id: response.data.createSrEvent.event.id}));
        });

        this.setDeleteSuccessCallback(() => {
            this.props.history.push(getLink('events.index'));
        });

        this.setUpdateErrorCallback(() => {
            this.props.Modal.setModal({
                isVisible: false,
            });
        });

        this.setUpdateSuccessCallback(() => {
            this.backToEventPage();
            refetchQueryByName('GetEventContents');
            refetchQueryByName('GetEventPresetsByEvent');
            refetchQueryByName('encodingTargetsQuery');
            refetchQueryByName('EventQuery');
        });

        this.setField(['home_competitor_id', 'away_competitor_id'], {
            onSearchChangeSelect: _debounce(this.getCompetitors, 1000),
        });

        this.setField('stadium', {
            onSearchChangeSelect: _debounce(this.getStadiums, 1000),
        });

        this.setOnChangeCallback({
            property_licence: (data) => {
                this.setState(() => ({
                    selectedPropertyLicenceId: data.value,
                }));

                if (data.value) {
                    this.getPresetsForPropertyLicence(data.value, _get(this.state, 'defaultForm_data.event.id', null));

                    this.setField('property_licence', {
                        showLink: true,
                        linkParam: data.value,
                    });

                    if (!this.props.Model.editForm) {
                        this.setField('tournament_id', {
                            disabled: true,
                            loading: true,
                        });
                    }

                    this.setField(['start_datetime', 'end_datetime'], {disabled: true, loading: false});
                    this.hideCoverage();
                    this.getRightsScopes(data.value).then((response) => {
                        if (!this.props.Model.editForm) {
                            this.setField('tournament_id', {
                                disabled: false,
                                defaultValue: null,
                                loading: false,
                                options: this.getTournaments(data.value, response),
                            });
                        }
                    });

                    this.setField(['court', 'home_competitor_id', 'away_competitor_id'],
                        {options: [], defaultValue: null, disabled: true});

                    this.getProductsForCoverage(data.value);
                    this.showCourtOrStadiumInput(data.value);
                }
            },
            tournament_id: (data) => {
                this.setState(() => ({
                    selectedTournamentId: data.value,
                }));

                if (data.value) {
                    this.setField(['home_competitor_id', 'away_competitor_id', 'court', 'stadium'], {
                        disabled: false,
                        options: [],
                        defaultValue: null,
                    });
                    this.setField(['start_datetime', 'end_datetime'], {
                        disabled: false,
                    });

                    const sportId = _get(this.state, 'propertyLicence.sport.id', 0);

                    let tournamentObject = this.state.tournamentsObjects[data.value];

                    if (parseInt(sportId, 10) === SPORT_TENNIS) {
                        this.setField('court', {loading: true});

                        this.getStadiumsByTournament(tournamentObject.id)
                            .then((stadiums) => {
                                this.setField('court', {
                                    disabled: false,
                                    loading: false,
                                    options: stadiums,
                                });

                                this.setState(() => ({stadiumsList: stadiums}));
                            });
                    }
                }

                if (!data.value) {
                    this.hideCoverage();
                }
            },
        });
    }

    renderSaveButton = () => {
        if (this.props.Model.editForm) {
            return super.renderSaveButton({
                content: 'Save',
            });
        }

        return super.renderSaveButton();
    };
}

export default withRouter(withApollo(SportradarNonExistingEventForm));
