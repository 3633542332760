import moment from 'moment';
import PropTypes from 'prop-types';

import {EVENT_CONTENT_VARIANT_STANDARD_FEED} from '@constants/variables';
import {getYesOrNo} from '@utils/helpers';

export const exportExcel = (dataExport, invoiceableProducts) => {
    const findByContentAndProduct = (data, content, product) => (
        data.find(d => (d.event_content_id === content.event_content_id && d.product_id === product.product_id))
    );

    const parseCategoryLevel = (categoryLevel) => (
        Array.isArray(categoryLevel) ? categoryLevel.join(' | ') : categoryLevel
    );

    const parseContents = (event) => {
        let contents = [];

        event.contents.forEach(content => {
            let contributionTypeName = event.contribution_types.find(contributionType =>
                contributionType.event_content_id === content.event_content_id
            ).name;

            let products = [];

            const nonStandardFeedContentVariant = content.eventContentVariant.id !== EVENT_CONTENT_VARIANT_STANDARD_FEED
                ? `(${content.eventContentVariant.short_name})`
                : '';

            event.products
                .filter(product => product.event_content_id === content.event_content_id)
                .forEach(product => {
                    let distributionTypesName = findByContentAndProduct(event.distribution_types, content, product).name;
                    let productStatusesName = findByContentAndProduct(event.product_statuses, content, product).name;
                    let targets = findByContentAndProduct(event.targets, content, product).targets;
                    let bookings = findByContentAndProduct(event.bookings, content, product).bookings;

                    products.push(`${product.short_name} (${productStatusesName}) - ${distributionTypesName} - ${targets || 0} - ${bookings || 0}`);
                });

            contents.push(`${content.name} ${nonStandardFeedContentVariant} - ${contributionTypeName} [${products.join(' | ')}]`);
        });

        return contents.join(', ');
    };

    const getProductsInvoiceStatuses = (productsInvoiceStatus, productShortNames) => {
        return productShortNames.map(shortName => productsInvoiceStatus[shortName] || '');
    };

    const getData = (event) => {
        const productsInvoiceStatus = event.products
            .filter(product => product.is_invoiceable)
            .reduce((acc, product) => {
                acc[product.short_name] = product.invoice_status_name;

                return acc;
            }, {});

        return [
            event.event_id,
            event.match_id,
            `${moment(event.start_datetime).format('YYYY-MM-DD')}`,
            `${moment(event.start_datetime).format('HH:mm')}`,
            `${event.end_datetime ? moment(event.end_datetime).format('YYYY-MM-DD') : ''}`,
            `${event.end_datetime ? moment(event.end_datetime).format('HH:mm') : ''}`,
            parseCategoryLevel(event.sport_or_content_category_level_1),
            parseCategoryLevel(event.category_or_content_category_level_2),
            parseCategoryLevel(event.tournament_or_content_category_level_3),
            event.venue_or_court,
            event.competitors,
            event.description,
            event.competitors_warnings.join(', '),
            event.status,
            event.coverage,
            parseContents(event),
            getYesOrNo(event.has_event_report),
            event.origin_stream_status,
            event.comment,
            event.event_issue_types?.map(type => type.name).sort().join(', '),
            ...getProductsInvoiceStatuses(productsInvoiceStatus, Object.values(invoiceableProducts)),
            event.notes,
        ];
    };

    const getColumns = () => {
        let columns = [
            'ID',
            'Match ID',
            'Date start',
            'Time start',
            'Date end',
            'Time end',
            'Sport / 1st level category',
            'Category / 2nd level category',
            'Tournament / 3rd level category',
            'Venue / Court',
            'Competitors',
            'Description',
            'Warnings',
            'Status',
            'Coverage',
            'Content (Content variant short name) - Contribution type [Product (Product status) - Distribution types - Targets - Bookings]',
            'Report?',
            'Origin stream status',
            'Comment',
            'Issue types',
        ];

        if (invoiceableProducts) {
            const invoiceStatuses = Object.values(invoiceableProducts).map(shortName => `Invoice status ${shortName}`);

            columns.push(...invoiceStatuses);
        }

        columns.push('Notes');

        return columns;
    };

    return {
        dataExport,
        filename: 'events',
        parseData: event => getData(event),
        path: 'events.index.path',
        titles: getColumns(),
    };
};

exportExcel.propTypes = {
    dataExport: PropTypes.array.isRequired,
};
