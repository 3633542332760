import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {EVENT as RESOURCE_EVENT} from '@constants/resources';
import {EVENT_STATUS_PLANNED, REQUIRE_THREE_LETTERS_PLACEHOLDER} from '@constants/variables';
import {updateAvEvent} from '@graphql/avEvent/mutation';
import * as eventsGraphQl from '@graphql/events/events';

import * as formUtils from '../utils/formHelper';

export default function EventsNonSportradarModel() {
    return {
        entityDataMapKey: 'event',
        entityLabel: 'event',
        formName: 'EventsNonSportradar',
        label: 'Event',
        resources: RESOURCE_EVENT, // optional, by default form is open for all actions
        showChangeLog: true, // optional, true by default
        title: (data, type) => {
            if (data && data.event && type === "edit") {
                return `Edit non-Sportradar event ${data.event.id}`;
            }

            return "Create non-Sportradar event";
        },
        mutation: {
            updateEntity: updateAvEvent,
            createEntity: eventsGraphQl.avEventCreateMutation,
            deleteEntity: eventsGraphQl.avEventDeleteMutation,
        },
        query: {
            getEntity: eventsGraphQl.event,
            getOptions: eventsGraphQl.avOptionsQuery,
        },
        messages: {
            boxName: {
                delete: 'eventsIndex',
                success: 'event',
                error: 'event',
            },
            text: MESSAGES,
            delete: MESSAGES.EVENT_DELETE_STATUS_TEXT_OBJECT,
            create: MESSAGES.EVENT_CREATE_UPDATE_STATUS_TEXT_OBJECT,
            update: MESSAGES.EVENT_CREATE_UPDATE_STATUS_TEXT_OBJECT,
        },
        dataMap: {
            event: {
                id: '',
                description: '',
                start_datetime: null,
                end_datetime: null,
                color: '000000',
                event_status: {
                    id: '',
                },
            },
            countries: [],
            status: [],
        },

        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'event.av_event.id',
                props: {
                    label: '',
                    name: 'id',
                    hidden: true,
                },
            },
            property_licence_id: {
                dataMapKey: 'event.property_licence.id',
                optionsKey: 'property_licences',
                props: {
                    component: formUtils.renderPropertyLicenceField,
                    label: 'Property licence',
                    required: true,
                    name: 'property_licence',
                    placeholder: 'Select property licence',
                    type: 'select',
                },
            },
            //Dummy field for non Sportradar Event
            event_preset: {
                dataMapKey: null,
                props: {
                    component: () => (null),
                    name: 'event_preset',
                },
            },
            as_additional_contents: {
                dataMapKey: 'as_additional_contents',
                props: {
                    component: () => (null),
                    name: 'as_additional_contents',
                },
            },
            content_category_id: {
                dataMapKey: 'event.av_event.content_category.id',
                optionsKey: 'content_categories',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Content category',
                    required: true,
                    name: 'content_category',
                    disabled: true,
                },
            },
            home_competitor: {
                dataMapKey: 'event.av_event.home_competitor.id',
                props: {
                    component: Form.SemanticSelect,
                    type: 'select',
                    placeholder: REQUIRE_THREE_LETTERS_PLACEHOLDER,
                    label: 'Home competitor',
                    name: 'home_competitor',
                    allowClear: true,
                },
            },
            away_competitor: {
                dataMapKey: 'event.av_event.away_competitor.id',
                props: {
                    component: Form.SemanticSelect,
                    type: 'select',
                    placeholder: REQUIRE_THREE_LETTERS_PLACEHOLDER,
                    label: 'Away competitor',
                    name: 'away_competitor',
                    allowClear: true,
                },
            },
            venue: {
                dataMapKey: 'event.av_event.venue.id',
                optionsKey: 'venues',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Venue',
                    name: 'venue',
                    allowClear: true,
                    placeholder: 'Select venue',
                },
            },
            start_datetime: {
                dataMapKey: 'event.start_datetime',
                props: {
                    component: Form.SemanticInput,
                    configuration: {
                        showTimeSelect: true,
                        timeFormat: 'HH:mm',
                        timeIntervals: 5,
                    },
                    label: 'Start time',
                    name: 'start_datetime',
                    required: true,
                    type: 'date',
                    disabled: true,
                },
            },
            end_datetime: {
                dataMapKey: 'event.end_datetime',
                props: {
                    component: Form.SemanticInput,
                    configuration: {
                        showTimeSelect: true,
                        timeFormat: 'HH:mm',
                        timeIntervals: 5,
                    },
                    label: 'End time',
                    name: 'end_datetime',
                    required: true,
                    type: 'date',
                    disabled: true,
                },
            },
            multiple: [
                {
                    description: {
                        dataMapKey: 'event.description',
                        props: {
                            component: Form.SemanticInput,
                            label: 'Description',
                            name: 'description',
                            required: true,
                            type: 'text',
                        },
                    },
                },
                {
                    color: {
                        dataMapKey: 'event.color',
                        props: {
                            component: Form.SemanticInput,
                            name: 'color',
                            type: 'color',
                        },
                        defaultValue: '3a87ad',
                    },
                },
            ],
            event_status: {
                dataMapKey: 'event.event_status.id',
                optionsKey: 'event_status',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Status',
                    name: 'event_status',
                    required: true,
                },
                defaultValue: EVENT_STATUS_PLANNED + '',
            },
            coverage: {
                props: {
                    component: Form.SemanticCheckboxList,
                    hidden: true,
                    type: 'checkbox',
                    name: 'coverage',
                    label: 'Coverage',
                    allowChildless: true,
                    className: 'inlineCheckboxList',
                },
            },
            notes: {
                component: Form.FormRowNotes,
                dataMapKey: 'event.notes',
                props: {
                    name: 'notes',
                },
            },
        },
    };
}
