import React from 'react';
import PropTypes from 'prop-types';
import {
    isEmpty as _isEmpty,
} from 'lodash';

import {coverage} from '@modules/events/utils/EventsTabsLayout/Details/EventDetails/fields/column1/coverage';
import {AppliedPreset} from '@modules/events/components/EventsTabsLayout/Details/EventDetails/column1/AppliedPreset';
import {EventId} from '@modules/events/components/EventsTabsLayout/Details/EventDetails/column1/EventId';

export const getFieldsList = (event) => {
    let fieldsColumn = [
        {
            name: 'ID',
            value: <EventId event={event}/>,
        },
        {
            name: 'Type',
            value: event.event_type.name,
        },
        {
            name: 'Applied preset',
            value: <AppliedPreset event={event}/>,
        },
    ];

    if (!_isEmpty(event.products)) {
        fieldsColumn.push(
            {
                name: 'Coverage',
                value: coverage(event.products),
            }
        );
    }

    return fieldsColumn;
};

getFieldsList.propTypes = {
    event: PropTypes.shape({
        event_type: PropTypes.shape({
            name: PropTypes.string,
        }),
    }).isRequired,
};

getFieldsList.defaultProps = {
    event: {
        event_type: {
            name: null,
        },
    },
};
